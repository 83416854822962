export default class Card {
  get _CLASSES() {
    return {
      SELECTED: 'is-selected',
    }
  }

  constructor() {
    this.cardGroup = document.querySelector(`[data-elm='cardGroup']`)
    if (!this.cardGroup) return
    this.cards = this.cardGroup.children
    this.cardAnimation = this._cardAnimation.bind(this)
    this.init()
  }

  init() {
    const { cards } = this
    cards.forEach((element) => {
      const elm = element.querySelector(`[data-elm='card']`)
      Velocity(elm, { rotateY: 0 }, { duration: 0 })
      elm.addEventListener('click', this.cardAnimation.bind(this))
    })
  }

  async _cardAnimation(event) {
    const { cardGroup, _CLASSES } = this
    const elm = event.currentTarget
    const elmClassList = elm.classList
    if (cardGroup.isAnimation) return
    cardGroup.isAnimation = true
    if (elmClassList.contains(_CLASSES.SELECTED)) {
      this._close()
    } else {
      await Promise.all([this._open(elm), this._close()])
      elmClassList.add(_CLASSES.SELECTED)
    }
  }

  _open(elm) {
    const { cardGroup } = this
    new Promise((resolve) => {
      Velocity(
        elm,
        { rotateY: '-=180' },
        {
          duration: 600,
          easing: 'easeInOutQuad',
          complete: () => {
            cardGroup.isAnimation = false
            resolve()
          },
        }
      )
    })
  }

  _close() {
    new Promise((resolve) => {
      const { cardGroup, _CLASSES } = this
      const open = cardGroup.querySelectorAll(`.${_CLASSES.SELECTED}[data-elm='card']`)
      open.forEach((elm) => elm.classList.remove(_CLASSES.SELECTED))
      Velocity(
        open,
        { rotateY: '-=180' },
        {
          duration: 600,
          easing: 'easeInOutQuad',
          complete: (elm) => {
            elm.forEach((elm) => (elm.style.transform = 'rotateY(0deg)'))
            cardGroup.isAnimation = false
            resolve()
          },
        }
      )
    })
  }
}
