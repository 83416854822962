/*
	world.js
*/

var _this
import * as THREE from 'three'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class world {
  constructor(_dom, _bgColor, _mode) {
    //	scope
    _this = this

    //	props
    this.scene
    this.camera
    this.focus
    this.renderer
    // this.directional;
    // this.ambient;
    this.clock
    this.time
    this.timeScale
    this.delta
    this.animationKey
    this.controls
    this.raycaster

    // extends props
    this.composer

    //	execute
    _bgColor = _bgColor == undefined ? 0xffffff : _bgColor
    _mode = _mode == undefined ? 0 : _mode
    this.init(_dom, _bgColor)

    window.addEventListener('resize', _this.resize)
    window.addEventListener('orientationchange', _this.orientationchange)
    this.resize()
  }

  init(_dom, _bgColor) {
    this.clock = new THREE.Clock()
    this.time = 0
    this.timeScale = 1.0

    var _near = 160
    var _far = 1600

    var width = window.innerWidth
    var height = window.innerHeight
    this.scene = new THREE.Scene()
    this.scene.fog = new THREE.Fog(_bgColor, _near, _far)
    // this.scene.fog = new THREE.FogExp2( _bgColor, 0.00090);

    // this.scene.background = new THREE.TextureLoader().load('assets/models/skybg.jpg');

    this.camera = new THREE.PerspectiveCamera(60, width / height, 0.01, _far)
    // this.camera = new THREE.OrthographicCamera( - width * 0.5, width * 0.5, height * 0.5, - height * 0.5, 0.1, 2000 );
    this.camera.position.set(0, 0, 500)
    this.focus = new THREE.Vector3(0, 0, 0)

    this.renderer = new THREE.WebGLRenderer({ antialias: false, canvas: _dom })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(width, height)
    this.renderer.setClearColor(_bgColor)
    // this.renderer.autoClearColor = false;
    // this.renderer.autoClear = false;

    this.renderer.render(this.scene, this.camera)
    this.renderer.outputEncoding = THREE.GammaEncoding

    this.raycaster = new THREE.Raycaster()

    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.autoRotate = true
    this.controls.autoRotateSpeed = 0.1

    this.controls.enableDamping = true
    this.controls.dampingFactor = 0.15
    this.controls.enableZoom = true

    this.controls.enabled = true
    this.controls.target = this.focus

    //	this.controls.noKeys = false;

    // this.controls.minDistance = 10;
    // this.controls.maxDistance = 3200;

    // this.controls.minPolarAngle = 0; // radians
    // this.controls.maxPolarAngle = Math.PI * 0.45; // radians
  }

  render() {
    _this.animationKey = window.requestAnimationFrame(function (e) {
      _this.render(e)
    })

    _this.delta = _this.clock.getDelta()
    _this.time += _this.delta * _this.timeScale

    _this.camera.lookAt(_this.focus)
    _this.controls.update()

    _this.renderer.render(_this.scene, _this.camera)
  }

  resize() {
    //	console.log('world.resize.')
    var width = window.innerWidth
    var height = window.innerHeight

    _this.resizeAngle()

    _this.renderer.setSize(width, height)
    if (_this.camera.aspect) {
      _this.camera.aspect = width / height
    } else {
      _this.camera.left = -width * 0.5
      _this.camera.right = width * 0.5
      _this.camera.bottom = -height * 0.5
      _this.camera.top = height * 0.5
    }
    _this.camera.updateProjectionMatrix()
  }

  orientationchange(e) {
    console.log('resize....')
    var width = window.innerWidth
    var height = window.innerHeight

    _this.resizeAngle()

    _this.renderer.setSize(width, height)
    if (_this.camera.aspect) {
      _this.camera.aspect = width / height
    } else {
      _this.camera.left = -width * 0.5
      _this.camera.right = width * 0.5
      _this.camera.bottom = -height * 0.5
      _this.camera.top = height * 0.5
    }
    _this.camera.updateProjectionMatrix()
  }

  resizeAngle() {
    var width = window.innerWidth
    var height = window.innerHeight

    //	original
    if (width >= height * 1.5) {
      _this.focalLengthToFOV(50)
    } else if (width <= height) {
      _this.focalLengthToFOV(30)
    } else {
      var _par = (width / height - 1.0) / 0.5
      var _fov = _par * 20 + 30
      _this.focalLengthToFOV(_fov)
    }
  }

  getWorldToScreen2D(_mesh) {
    var vector = new THREE.Vector3()
    var _ctx = this.renderer.getContext()
    var widthHalf = 0.5 * _ctx.canvas.width
    var heightHalf = 0.5 * _ctx.canvas.height
    _mesh.updateMatrixWorld()
    vector.setFromMatrixPosition(_mesh.matrixWorld)
    vector.project(this.camera)
    vector.x = vector.x * widthHalf + widthHalf
    vector.y = -(vector.y * heightHalf) + heightHalf

    var _dir0 = new THREE.Vector3().subVectors(this.focus, this.camera.position)
    var _dir1 = new THREE.Vector3().subVectors(_mesh.position, this.camera.position)
    var _d = _dir0.dot(_dir1)
    if (_d <= 0) {
      vector.x = -9999
      vector.y = -9999
    }

    return {
      x: ~~vector.x,
      y: ~~vector.y,
    }
  }

  focalLengthToFOV(_focalLength) {
    var _h = this.camera.filmGauge //	(36mm * 24mm (フルサイズ) の対角線の長さを算出)
    var _v = (_h * 2) / 3
    var _diagonalLine = Math.sqrt(_h * _h + _v * _v)
    this.camera.fov = (180.0 / Math.PI) * Math.atan(_diagonalLine / (_focalLength * 2.0)) * 2.0
    this.camera.updateProjectionMatrix()
  }

  fovToFocalLength() {
    var _h = this.camera.filmGauge //	(36mm * 24mm (フルサイズ) の対角線の長さを算出)
    var _v = (_h * 2) / 3
    var _diagonalLine = Math.sqrt(_h * _h + _v * _v)
    var _focalLength = _diagonalLine / Math.tan((this.camera.fov * Math.PI) / 180.0 / 2.0) / 2.0
    return _focalLength
  }

  pixelEqualMagnification() {
    var _dist = (window.innerHeight * 0.5) / Math.tan((this.camera.fov * 0.5 * Math.PI) / 180)
    return _dist
  }

  add(e) {
    this.scene.add(e)
  }

  remove(e) {
    e.geometry.dispose()

    if (e.material.uniforms != undefined) {
      for (var i in e.material.uniforms.value.dispose != undefined) {
        e.material.uniforms[i].value.dispose()
      }
    }

    if (e.material.map != undefined) {
      e.material.map.dispose()
    }

    this.scene.remove(e)
  }

  addPass(e) {}

  renderStart() {
    this.renderStop()
    this.clock.start()
    this.render(0)
    // this.controls.enabled = true;
  }

  renderStop() {
    window.cancelAnimationFrame(this.animationKey)
    this.clock.stop()
    // this.controls.enabled = false;
  }

  dispose() {
    var _this = this

    this.renderStop()
    window.removeEventListener('resize', _this.resize)
    window.removeEventListener('orientationchange', _this.orientationchange)

    this.renderer.dispose()
    this.controls.dispose()

    this.clock = null
    this.scene = null
    this.camera = null
    this.focus = null
    this.raycaster = null
    this.renderer = null
    this.controls = null
  }
}

export class FilterWorld extends world {
  constructor(_dom, _bgColor, _mode) {
    super(_dom, _bgColor, _mode)
  }

  init(_dom, _bgColor) {
    super.init(_dom, _bgColor)

    var width = window.innerWidth
    var height = window.innerHeight

    // var parameters = { minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter, format: THREE.RGBAFormat, stencilBuffer: false };
    // var renderTarget = new THREE.WebGLRenderTarget( width, height, parameters );
    // this.composer = new EffectComposer( this.renderer, renderTarget );

    this.composer = new EffectComposer(this.renderer)
    this.composer.setSize(width, height)
    this.composer.addPass(new RenderPass(this.scene, this.camera))

    var _copySahder = new ShaderPass(CopyShader)
    _copySahder.renderToScreen = true
    this.composer.addPass(_copySahder)
  }

  render() {
    _this.animationKey = window.requestAnimationFrame(function (e) {
      _this.render()
    })

    var _delta = _this.clock.getDelta()
    _this.time += _delta

    // _this.controls.update();
    // _this.camera.lookAt( _this.focus );
    _this.renderer.render(_this.scene, _this.camera)

    var len = _this.composer.passes.length
    for (var i = 1; i < len - 1; i++) {
      if (_this.composer.passes[i].uniforms && _this.composer.passes[i].uniforms.time) {
        _this.composer.passes[i].uniforms.time.value += _delta
      }
    }

    _this.composer.render()
  }

  resize() {
    var width = window.innerWidth
    var height = window.innerHeight

    var len = _this.composer.passes.length
    for (var i = 1; i < len - 1; i++) {
      if (_this.composer.passes[i].uniforms && _this.composer.passes[i].uniforms.resolution) {
        _this.composer.passes[i].uniforms.resolution.value.x = width
        _this.composer.passes[i].uniforms.resolution.value.y = height
      }
    }

    _this.renderer.setSize(width, height)
    _this.composer.setSize(width, height)
    if (_this.camera.aspect) {
      _this.camera.aspect = width / height
    } else {
      _this.camera.left = -width * 0.5
      _this.camera.right = width * 0.5
      _this.camera.bottom = -height * 0.5
      _this.camera.top = height * 0.5
    }
    _this.camera.updateProjectionMatrix()

    //	update view
    // _this.controls.update();
    // _this.camera.lookAt( _this.focus );
    _this.composer.render()
  }

  orientationchange() {
    var width = window.innerWidth
    var height = window.innerHeight

    var len = _this.composer.passes.length
    for (var i = 1; i < len - 1; i++) {
      if (_this.composer.passes[i].uniforms && _this.composer.passes[i].uniforms.resolution) {
        _this.composer.passes[i].uniforms.resolution.value.x = width
        _this.composer.passes[i].uniforms.resolution.value.y = height
      }
    }

    _this.renderer.setSize(width, height)
    _this.composer.setSize(width, height)
    if (_this.camera.aspect) {
      _this.camera.aspect = width / height
    } else {
      _this.camera.left = -width * 0.5
      _this.camera.right = width * 0.5
      _this.camera.bottom = -height * 0.5
      _this.camera.top = height * 0.5
    }
    _this.camera.updateProjectionMatrix()

    //	update view
    // _this.controls.update();
    // _this.camera.lookAt( _this.focus );
    _this.composer.render()
  }

  //	extends
  addPass(e) {
    var len = _this.composer.passes.length
    _this.composer.passes.splice(len - 1, 0, e)
  }

  removePass(e) {
    var len = _this.composer.passes.length
    e = e < 1 ? 1 : e > len - 2 ? len - 2 : e
    _this.composer.passes.splice(e, 1)
  }
}
