export default class Top {
  constructor() {
    this.body = document.body
    if (!this.body.classList.contains('page-top')) return
    this.wrap = document.querySelector(`[data-elm-content]`)
    this.setHeight = this._setHeight.bind(this)
    this.setHeight()
    window.addEventListener('resize', this.setHeight)
  }

  _setHeight() {
    const { innerHeight } = window
    this.wrap.style.height = `${innerHeight}px`
  }
}
