export default class ModalSlider {
  get Opt() {
    return {
      duration: 600,
      easing: 'easeOutQuad',
      offset: 0,
    }
  }

  constructor(elm) {
    this.elm = elm
    const Opt = {
      centeredSlides: true,
      slidesPerView: 'auto',
      loop: true,
      slideToClickedSlide: true,
      autoplay: {
        delay: 4000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
        reverseDirection: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        bulletActiveClass: 'is-selected',
      },
    }
    this.controller = new window.Swiper(this.elm, Opt)
  }
}
