import ArtworkGL from './ArtworkGL'

export default class Artwork {
  constructor() {
    this.elm = document.querySelector(`[data-elm='background']`)
    if (!this.elm) return
    this.artworkGL = new ArtworkGL({
      $canvas: this.elm,
      $bgColor: 0x000000,
    })
  }

  destroyed() {
    this.artworkGL.dispose()
  }
}
