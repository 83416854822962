export default class TextFadeIn {
  constructor(elm, type) {
    if (type !== 'textFadeIn') {
      return true
    }
    this.elm = elm
    this.text = [...this.elm.querySelectorAll(`[data-split='str']`)]
    this.init()
  }

  init() {
    const { text } = this
    let sequence = []
    sequence.push({
      e: text,
      p: {
        visibility: 'hidden',
      },
      o: {
        visibility: 'hidden',
        duration: 0,
      },
    })
    window.Velocity.RunSequence(sequence)
  }
  run() {
    const { text } = this
    text.forEach((elm, index) => {
      window.Velocity({
        e: elm,
        p: {
          opacity: [1, 0],
        },
        o: {
          duration: 800,
          visibility: 'visible',
          delay: index * 60,
        },
      })
    })
  }
}
