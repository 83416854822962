import { detect } from 'core'

export default class Menu {
  get _Opt() {
    return {
      durationRate: 0.2,
      easing: 'easeOutQuad',
      offset: 0,
    }
  }

  constructor() {
    this.opened = false
    this.html = document.documentElement
    this.body = document.body
    this.htmlClassList = this.html.classList
    this.content = document.querySelector('[data-elm-content]')
    this.menuButton = document.querySelector(`[data-elm='menuButton']`)
    if (!this.menuButton) {
      return true
    }
    this.nav = document.querySelector(`[data-elm='nav']`)
    this.link = [...this.nav.querySelectorAll('a')]
    this.link.forEach((_elm) => {
      // _elm.addEventListener('click', (event) => this.moveLink(event, _elm))
      _elm.addEventListener('click', () => this.close())
    })
    this.menuButton.addEventListener('click', () => this.menuAction())
  }

  menuAction() {
    const action = this.opened ? 'close' : 'open'
    this[action]()
  }

  async moveLink(event, elm) {
    event.preventDefault()

    this._move(elm)
  }

  _move(elm) {
    let hash = elm.getAttribute('href')
    const link = elm.href
    if (hash.indexOf('#') !== 0) {
      const linkSeparate = link.split('#')
      if (linkSeparate[0] === location.href) {
        hash = link.replace(location.href, '')
        if (hash === '') {
          if (detect.isMobile()) {
            this.close()
          }
          return true
        }
        hash = hash === '#' ? 'html' : hash
        this._scroll(hash)
      } else {
        location.href = elm.getAttribute('href')
        return true
      }
    } else {
      location.href = elm.getAttribute('href')
    }
  }

  _scroll(elm) {
    const { _Opt } = this
    const _elm = document.querySelector(elm)
    const rect = _elm.getBoundingClientRect()
    window.Velocity(_elm, 'stop')
    window.Velocity(_elm, 'scroll', {
      duration: Math.abs(rect.top * _Opt.durationRate),
      easing: _Opt.easing,
      offset: _Opt.offset,
    })
  }

  open() {
    this.opened = true
    this.htmlClassList.add('is-menu-opened')
    this._scrollEvent(true)
    window.Velocity(this.nav, 'fadeIn', {
      duration: 400,
    })
  }

  close() {
    return new Promise((resolve) => {
      this.opened = false
      this._scrollEvent(false)
      window.Velocity(this.nav, 'fadeOut', {
        duration: 400,
        complete: () => {
          this.htmlClassList.remove('is-menu-opened')
          resolve()
        },
      })
    })
  }

  _scrollEvent(flg) {
    const { html, body, content } = this
    this.offsetPosition = content.getBoundingClientRect().top
    if (flg) {
      content.style.setProperty('top', `${this.offsetPosition}px`)
      content.style.setProperty('position', 'fixed')
      if (!detect.isiPad) {
        html.style.setProperty('overflow', 'hidden')
      }
      body.style.setProperty('overflow', 'hidden')
    } else {
      html.style.setProperty('overflow', '')
      body.style.setProperty('overflow', '')
      content.style.setProperty('position', '')
      content.style.setProperty('top', '')
      window.scroll(0, Math.abs(this.offsetPosition))
    }
  }
}
