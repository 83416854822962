export default class FadeInMultiGroup {
  constructor(elm, type) {
    if (type !== 'fadeInMultiGroup') {
      return true
    }
    this.elm = elm
    this.item = [...this.elm.querySelectorAll(`[data-elm='groupItem']`)]
    this.init()
  }

  init() {
    const { item } = this
    let sequence = []
    sequence.push({
      e: item,
      p: {
        visibility: 'hidden',
      },
      o: {
        visibility: 'hidden',
        duration: 0,
      },
    })
    window.Velocity.RunSequence(sequence)
  }
  run(elm, index) {
    window.Velocity({
      e: elm,
      p: {
        opacity: [1, 0],
      },
      o: {
        duration: 800,
        visibility: 'visible',
        delay: index * 100,
      },
    })
  }
}
