import AddClass from './AddClass'
import FadeIn from './FadeIn'
import TextFadeIn from './TextFadeIn'
import FadeInMultiGroup from './FadeInMultiGroup'
import SlideInMulti from './SlideInMulti'

export default class ScrollAnimation {
  constructor(element) {
    this.element = element
    this.type = element.dataset.scrollAnime
    this.rate = element.dataset.rate || '-10%'
    this.full = element.dataset.full || false
    this.threshold = []
    for (let count = 0; count <= 100; count += 1) {
      this.threshold.push(count / 100)
    }
    this.opt = {
      root: null,
      rootMargin: `${this.rate} 0px`,
      threshold: this.threshold,
    }
    this.clientH = document.documentElement.clientHeight

    // Animation Type
    this.addClass = new AddClass(element, this.type)
    this.fadeIn = new FadeIn(element, this.type)
    this.textFadeIn = new TextFadeIn(element, this.type)
    this.fadeInMultiGroup = new FadeInMultiGroup(element, this.type)
    this.slideInMulti = new SlideInMulti(element, this.type)
    this.animeRun = {
      addClass: () => this.addClass.run(),
      fadeIn: () => this.fadeIn.run(),
      textFadeIn: () => this.textFadeIn.run(),
      fadeInMultiGroup: (elm, index) => this.fadeInMultiGroup.run(elm, index),
      slideInMulti: () => this.slideInMulti.run(),
    }

    if (this.type.indexOf('Group') !== -1) {
      this.initGroup()
    } else {
      this.initSingle()
    }
  }

  initSingle() {
    const { element } = this
    element.observer = new IntersectionObserver((entries) => this.observation(entries), this.opt)
    element.observer.observe(element)
  }

  initGroup() {
    const { element } = this
    this.groupArray = {}
    this.groupItem = [...element.querySelectorAll(`[data-elm='groupItem']`)]
    this.groupItem.forEach((_elm) => {
      const bounds = parseInt(_elm.getBoundingClientRect().top)
      if (bounds in this.groupArray) {
        _elm.groupIndex = this.groupArray[bounds].length
        this.groupArray[bounds].push(_elm)
      } else {
        this.groupArray[bounds] = [_elm]
        _elm.groupIndex = 0
      }
      _elm.observer = new IntersectionObserver((entries) => this.observation(entries, _elm.groupIndex), this.opt)
      _elm.observer.observe(_elm)
    })
  }

  observation(entries, index) {
    const { full } = this
    entries.forEach((entry) => {
      if (full) {
        if (entry.intersectionRatio > 0.75) {
          this.animeRun[this.type](entry.target, index)
          entry.target.observer.unobserve(entry.target)
        }
      } else {
        if (entry.isIntersecting) {
          this.animeRun[this.type](entry.target, index)
          entry.target.observer.unobserve(entry.target)
        }
      }
    })
  }
}
