export default class FadeIn {
  constructor(elm, type) {
    if (type !== 'fadeIn') {
      return true
    }
    this.elm = elm
    this.init()
  }

  init() {
    const { elm } = this
    let sequence = []
    sequence.push({
      e: elm,
      p: {
        visibility: 'hidden',
      },
      o: {
        visibility: 'hidden',
        duration: 0,
      },
    })
    window.Velocity.RunSequence(sequence)
  }
  run() {
    const { elm } = this
    const style = window.getComputedStyle(elm)
    const opacity = style.getPropertyValue('opacity')
    window.Velocity({
      e: elm,
      p: {
        opacity: [opacity, 0],
      },
      o: {
        duration: 800,
        visibility: 'visible',
        complete: () => {
          elm.style.opacity = ''
        },
      },
    })
  }
}
