export default class SlideInMulti {
  constructor(elm, type) {
    if (type !== 'slideInMulti') {
      return true
    }
    this.elm = elm
    this.item = [...elm.querySelectorAll(`[data-elm='item']`)]
    this.init()
  }

  init() {
    const { item } = this
    let sequence = []
    sequence.push({
      e: item,
      p: {
        visibility: 'hidden',
      },
      o: {
        visibility: 'hidden',
        duration: 0,
      },
    })
    window.Velocity.RunSequence(sequence)
  }

  run() {
    const { item } = this
    item.forEach((_elm, index) => {
      const delay = _elm.dataset.delay || 0
      window.Velocity({
        e: _elm,
        p: {
          translateY: ['0px', 'easeOutSine', '15px'],
          opacity: [1, 0],
        },
        o: {
          duration: 600,
          visibility: 'visible',
          delay: index * 400 + delay,
        },
      })
    })
  }
}
