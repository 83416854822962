import 'intersection-observer'
import Velocity from 'velocity-animate/velocity.min'
import 'velocity-animate/velocity.ui.min'
import Swiper from 'swiper/swiper-bundle.min.js'
window.Velocity = Velocity
window.Swiper = Swiper

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector
}

import Loading from 'ui/Loading'
import Menu from 'ui/Menu'
import Card from 'ui/Card'
import CatchAnime from 'ui/CatchAnime'
import Artwork from 'ui/Artwork'
import ArtworkThumb from 'ui/ArtworkThumb'
import ModalSlider from 'ui/ModalSlider'
import ScrollAnimation from 'ui/ScrollAnimation'
import Slider from 'ui/Slider'
import Top from 'page/Top'

export default class App {
  constructor() {
    new Loading()
    new Artwork()
    new Menu()
    new Top()
    new Card()
    const fnArr = [
      [CatchAnime, `[data-elm='catch']`],
      [ModalSlider, `[data-elm='modal-slider']`],
      [Slider, `[data-elm='slider']`],
      [ArtworkThumb, `[data-elm='artwork-thumb']`],
    ]
    Array.from(fnArr, (arr) => this.newClass(...arr))
    window.addEventListener('loaded', this.windowLoaded.bind(this))
  }

  windowLoaded() {
    const fnArr = [[ScrollAnimation, `[data-scroll-anime]`]]
    Array.from(fnArr, (arr) => this.newClass(...arr))
  }

  newClass(addClassName, elm, opts) {
    const element = [...document.querySelectorAll(elm)]
    Array.from(element, (_elm) => {
      if (opts) {
        new addClassName(_elm, opts)
      } else {
        new addClassName(_elm)
      }
    })
  }
}

new App()
