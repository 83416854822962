/*
	world.js
*/

import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class world {
  constructor(_dom, _bgColor, _width, _height) {
    console.warn = function () {}
    console.alert = function () {}

    //	props
    this.scene
    this.camera
    this.focus
    this.renderer
    // this.directional;
    // this.ambient;
    this.clock
    this.time
    this.timeScale
    this.delta
    this.animationKey
    this.controls
    this.raycaster

    // extends props
    this.composer

    //	execute
    _bgColor = _bgColor == undefined ? 0xffffff : _bgColor
    this.init(_dom, _bgColor, _width, _height)
  }

  init(_dom, _bgColor, _width, _height) {
    this.clock = new THREE.Clock()
    this.time = 0
    this.timeScale = 1.0

    var _far = 1600

    var width = _width
    var height = _height
    this.scene = new THREE.Scene()

    this.camera = new THREE.PerspectiveCamera(60, width / height, 0.01, _far)
    this.camera.position.set(0, 0, 500)
    this.focus = new THREE.Vector3(0, 0, 0)

    this.renderer = new THREE.WebGLRenderer({ antialias: false, canvas: _dom, alpha: true })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(width, height)
    this.renderer.autoClearColor = false
    this.renderer.autoClear = true

    this.renderer.render(this.scene, this.camera)
    this.renderer.outputEncoding = THREE.GammaEncoding

    this.raycaster = new THREE.Raycaster()

    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.autoRotate = true
    this.controls.autoRotateSpeed = 0.1

    this.controls.enableDamping = true
    this.controls.dampingFactor = 0.15
    this.controls.enableZoom = true

    this.controls.enabled = true
    this.controls.target = this.focus
  }

  render() {
    var _this = this
    _this.animationKey = window.requestAnimationFrame(function (e) {
      _this.render(e)
    })

    _this.delta = _this.clock.getDelta()
    _this.time += _this.delta * _this.timeScale

    _this.camera.lookAt(_this.focus)
    _this.controls.update()

    _this.renderer.clear()
    _this.renderer.render(_this.scene, _this.camera)
  }

  focalLengthToFOV(_focalLength) {
    var _h = this.camera.filmGauge //	(36mm * 24mm (フルサイズ) の対角線の長さを算出)
    var _v = (_h * 2) / 3
    var _diagonalLine = Math.sqrt(_h * _h + _v * _v)
    this.camera.fov = (180.0 / Math.PI) * Math.atan(_diagonalLine / (_focalLength * 2.0)) * 2.0
    this.camera.updateProjectionMatrix()
  }

  fovToFocalLength() {
    var _h = this.camera.filmGauge //	(36mm * 24mm (フルサイズ) の対角線の長さを算出)
    var _v = (_h * 2) / 3
    var _diagonalLine = Math.sqrt(_h * _h + _v * _v)
    var _focalLength = _diagonalLine / Math.tan((this.camera.fov * Math.PI) / 180.0 / 2.0) / 2.0
    return _focalLength
  }

  pixelEqualMagnification() {
    var _dist = (window.innerHeight * 0.5) / Math.tan((this.camera.fov * 0.5 * Math.PI) / 180)
    return _dist
  }

  add(e) {
    this.scene.add(e)
  }

  remove(e) {
    e.geometry.dispose()

    if (e.material.uniforms != undefined) {
      for (var i in e.material.uniforms.value.dispose != undefined) {
        e.material.uniforms[i].value.dispose()
      }
    }

    if (e.material.map != undefined) {
      e.material.map.dispose()
    }

    this.scene.remove(e)
  }

  addPass(e) {}

  renderStart() {
    this.renderStop()
    this.clock.start()
    this.render(0)
  }

  renderStop() {
    window.cancelAnimationFrame(this.animationKey)
    this.clock.stop()
  }

  dispose() {
    this.renderStop()
    this.renderer.dispose()
    this.controls.dispose()
    this.clock = null
    this.scene = null
    this.camera = null
    this.focus = null
    this.raycaster = null
    this.renderer = null
    this.controls = null
  }
}
