/*
	ArtworkGL.js
*/

import world from './world'
import Tweener from './Tweener'
import mainView from './mainView'

export default class ArtworkGL {
  constructor(props) {
    this.props = props
    this.world
    this.tweener
    this.loopKey0
    this.init()
  }

  init() {
    this.world = new world(this.props.$canvas, this.props.$bgColor, this.props.$width, this.props.$height)
    this.world.controls.enabled = false
    this.world.controls.autoRotate = false
    this.world.focalLengthToFOV(135)
    this.world.camera.position.z = 10
    this.world.camera.position.x = 5
    this.world.camera.position.y = 5
    this.world.render()

    Tweener.init()

    this.main = new mainView({
      world: this.world,
      raycaster: this.raycaster,
      tapPosition: this.tapPosition,
      bgColor: this.props.$bgColor,
      canvas: this.props.$canvas,
      id: this.props.$id,
      width: this.props.$width,
      height: this.props.$height,
    })
    this.world.add(this.main.scene)
  }

  renderStart(e) {
    console.log('on.renderStart, ArtworkGL.')
    this.main.renderStart()
    this.world.renderStart()
    this.loop()
  }
  renderStop(e) {
    console.log('on.renderStop, ArtworkGL.')
    this.main.renderStop()
    this.world.renderStop()
    window.cancelAnimationFrame(this.loopKey0)
  }

  dispose() {
    console.log('ArtworkGL.dispose')

    this.renderStop()

    this.world.scene.remove(this.main.scene)
    this.main.dispose()

    var len = this.world.scene.children.length
    for (var i = 0; i < len; i++) {
      var e = this.world.scene.children[i]

      this.world.scene.remove(e)
    }
    this.world.dispose()
  }
}
