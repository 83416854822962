/*
	ArtworkGL.js
*/

import * as THREE from 'three'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'
import Tweener from './Tweener'
import axios from 'axios'

export default class mainView {
  constructor(props) {
    //  �ѥ��`������
    this.props = props

    //  ������չ�_
    this.scene
    this.main

    //  ��Դ����
    this.lights = []
    this.lines = []
    this.particles
    this.particlesManager

    //  loopkey
    this.loopKey0

    this.json
    this.loadingData
    this.vector
    this.randomViewerKey
    this.timeoutKeyList

    //  �g��
    this.__init()
  }

  __init() {
    //  3Dϵ���ڻ�
    this.init()

    //  �ǩ`���i���z�ߡ���ǥ���`�ǥ��󥰤ʤ�
    var _this = this
    this.loadJSON(function () {
      //  callback;
      _this.layouts()
      _this.loop()

      _this.randomViewer()
    })
  }

  init() {
    var _this = this

    this.scene = new THREE.Scene()

    //  ȫ������Q��
    var _al = new THREE.AmbientLight(0xffffff, 0.6)
    this.scene.add(_al)

    var _dl = new THREE.PointLight(0xffffff, 1.0)
    _dl.position.set(10, 30, 20)
    _dl.castShadow = true // default false
    _dl.shadow.radius = 4
    this.props.world.add(_dl)

    this.lights.push(_al)
    this.lights.push(_dl)

    //  ������`�΁��O��
    var _renderer = this.props.world.renderer

    //  texture
    const toneMappingOptions = {
      None: THREE.NoToneMapping,
      Linear: THREE.LinearToneMapping,
      Reinhard: THREE.ReinhardToneMapping,
      Cineon: THREE.CineonToneMapping,
      ACESFilmic: THREE.ACESFilmicToneMapping,
      Custom: THREE.CustomToneMapping,
    }

    //�i���z������`�󤬰����Τ����뤵��֤��Ϥ��Ƥ���
    _renderer.outputEncoding = THREE.GammaEncoding

    this.timeoutKeyList = []
  }

  loadJSON(_callback) {
    //  path
    let _this = this

    // �����ǩ`����
    // JSON�i���z�ߤ����ǥ롢������i���z�ߤޤǤ��ԄӄI����
    //  ������΄I����_callback��ί�ͤ�
    const url = '/assets/models/smfgdata.json'
    let _json = axios.get(url).then((res) => {
      _this.json = res.data

      //console.log( _this.json );
      _this.json.coreData = []
      _this.json.models.reverse()
      _this.loadList(_callback)
    })
  }

  loadList(_callback) {
    let _this = this
    if (this.json.models.length) {
      let _path = this.json.models.pop()
      let _url = '/assets/models/' + _path

      //  ��ǥ��i���z��
      var loader = new FBXLoader()
      loader.load(
        _url,
        function (object) {
          object.scale.set(1, 1, 1)
          object.traverse(function (child) {
            child.scale.set(1, 1, 1)

            if (child.isMesh) {
              child.material.wireframe = true
            }
          })

          //  �ǩ`����ꥹ�Ȥ˥��ȥå�
          _this.json.coreData.push(object)

          // delay load.
          var _k = setTimeout(function () {
            _this.loadList(_callback)
          }, 16)
          _this.timeoutKeyList.push(_k)
        },
        function (_onProgress) {
          /*console.log(  _onProgress );*/
        },
        function (_onError) {
          console.log('ERROR: ', _onError)
        }
      )
    } else {
      _this.loadLabels(0, _callback)
    }
  }

  loadLabels(_count, _callback) {
    var _this = this
    var _smfg = this.json.data

    if (_count < _smfg.length) {
      var _dat = _smfg[_count]
      var _idx = _dat.type

      var _loader = new THREE.TextureLoader()
      _loader.load(
        // resource URL
        '/assets/models/' + _dat.label,

        // onLoad callback
        function (texture) {
          // in this example we create the material when the texture is loaded
          //  console.log( texture )

          _dat.label = texture
          _count++

          var _k = setTimeout(function () {
            _this.loadLabels(_count, _callback)
          }, 10)
          _this.timeoutKeyList.push(_k)
        },

        // onProgress callback currently not supported
        undefined,

        // onError callback
        function (err) {
          console.error('Label image loading error.', err)
        }
      )
    } else {
      _callback()
    }
  }

  layouts() {
    var _smfg = this.json.data

    var _group = {}

    for (var i in _smfg) {
      var _dat = _smfg[i]
      var _idx = _dat.type

      //  WRAPPER
      var _wrapper = new THREE.Object3D()
      this.scene.add(_wrapper)

      //  WIREFRAME
      var _master = this.json.coreData[_idx].children[0]
      var _geometry = _master.geometry.clone()
      var _material = _master.material.clone()
      var _material = new THREE.MeshBasicMaterial()
      _material.wireframe = true
      _material.color = new THREE.Color(_dat.color)
      _material.transparent = true
      _material.opacity = 0.25
      _material.blending = THREE.AdditiveBlending

      var _mesh = new THREE.Mesh(_geometry, _material)
      _wrapper.add(_mesh)

      //  WHITE CORE
      var _geometry = new THREE.IcosahedronGeometry(0.4, 1)
      var _material = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        transparent: true,
        opacity: 0.2,
      })
      var _core = new THREE.Mesh(_geometry, _material)
      _wrapper.add(_core)

      _dat.wrapper = _wrapper
      _dat.mesh = _mesh
      _dat.core = _core

      _dat.wrapper.position.x = (Math.random() - 0.5) * 12.0
      _dat.wrapper.position.y = (Math.random() - 0.5) * 12.0
      _dat.wrapper.position.z = (Math.random() - 0.5) * 12.0

      //  sphere
      var _len = _smfg.length - 1
      const phi = Math.acos(-1 + (2 * i) / _len)
      const theta = Math.sqrt(_len * Math.PI) * phi
      _dat.wrapper.position.setFromSphericalCoords(6, phi, theta)

      //  rotation
      _dat.wrapper.rotation.x = (Math.random() - 0.5) * Math.PI * 2.0
      _dat.wrapper.rotation.y = (Math.random() - 0.5) * Math.PI * 2.0
      _dat.wrapper.rotation.z = (Math.random() - 0.5) * Math.PI * 2.0

      //  LABEL
      var _w = _dat.label.image.width
      var _h = _dat.label.image.height
      var _material = new THREE.SpriteMaterial({
        transparent: true,
        map: _dat.label,
        color: 0xffffff,
        depthTest: false,
        // blending: THREE.AdditiveBlending
      })
      _dat.label = new THREE.Sprite(_material)
      // _dat.label.renderOrder = -1;

      var _size = 4
      _dat.label.scale.set(_size, _size, 1)
      _dat.wrapper.add(_dat.label)

      _group[_dat.id] = _dat
    }

    //  Line
    var _div = 64 * 4 * 2
    for (var i in _group) {
      var _p0 = _group[i]
      var _connection = _group[i].connection
      var len = _connection.length
      var _colors = new Float32Array((_div + 1) * 3)

      for (var j = 0; j < len; j++) {
        var _p1 = _group[_connection[j].id]

        var __p0 = _p0.wrapper.position.clone()
        var __p1 = new THREE.Vector3()
        var __p2 = new THREE.Vector3()
        var __p3 = _p1.wrapper.position.clone()

        var _power = Math.random() * 10.0 + 2.0
        var _dir0 = new THREE.Vector3(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5).normalize()
        var _dir1 = new THREE.Vector3(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5).normalize()

        __p1 = __p1.addVectors(__p0, _dir0.multiplyScalar(_power))
        __p2 = __p2.subVectors(__p3, _dir1.multiplyScalar(_power))

        var _curve = new THREE.CubicBezierCurve3(__p0, __p1, __p2, __p3)
        var _points = _curve.getPoints(_div)
        var _geometry = new THREE.BufferGeometry().setFromPoints(_points)
        var _material = new THREE.LineBasicMaterial({
          transparent: true,
          opacity: 0.3,
          blending: THREE.AdditiveBlending,
          depthTest: false,
          vertexColors: true,
        })

        //  add Color
        for (var k = 0; k < _div + 1; k++) {
          var _rad = (k / _div) * Math.PI * 2.0 - Math.PI * 0.5
          var _value = 1.0 - (Math.sin(_rad) * 0.5 + 0.5)
          _value = _value * 0.8 + 0.2
          _colors[k * 3 + 0] = _value
          _colors[k * 3 + 1] = _value
          _colors[k * 3 + 2] = _value
        }
        _geometry.setAttribute('color', new THREE.BufferAttribute(_colors, 3))

        var _line = new THREE.Line(_geometry, _material)
        this.scene.add(_line)
        this.lines.push(_line)
      }
    }

    //  particles;
    this.particlesManager = []

    var _this = this
    var _t = new THREE.TextureLoader().load(
      '/assets/models/circle0.png',
      function (_texture) {
        var _geometry = new THREE.Geometry()
        var _material = new THREE.PointsMaterial({
          transparent: true,
          blending: THREE.AdditiveBlending,
          size: 0.1,
          map: _texture,
          depthTest: false,
        })

        for (var i = 0; i < 64; i++) {
          var _idx = Math.floor(Math.random() * _this.lines.length)
          var _line = _this.lines[_idx]
          var _pos = Math.floor(Math.random() * _line.geometry.attributes.position.count)
          var _dir = Math.random() < 0.5 ? 1 : -1
          _dir *= Math.random() < 0.5 ? 1 : Math.random() < 0.5 ? 2 : 3
          _this.particlesManager[i] = {
            dir: _dir,
            line: _line,
            position: _pos,
          }

          var _arr = _line.geometry.attributes.position.array
          var _x = _arr[_pos * 3 + 0]
          var _y = _arr[_pos * 3 + 1]
          var _z = _arr[_pos * 3 + 2]

          _geometry.vertices[i] = new THREE.Vector3(_x, _y, _z)
        }
        _this.particles = new THREE.Points(_geometry, _material)
        _this.scene.add(_this.particles)
      },
      undefined,
      function (err) {}
    )
  }

  randomViewer() {
    if (this.json.data != undefined) {
      var _duration = 8.0

      var _this = this

      var _idx = Math.floor(Math.random() * this.json.data.length)
      var _dat = this.json.data[_idx]
      var _world = this.props.world
      var _camera = this.props.world.camera
      var _focus = this.props.world.focus

      //  camera focus
      Tweener.addTween(_world, {
        focus: _dat.wrapper.position,
        duration: _duration - 1.0,
        transition: 'easeInOutExpo',
      })

      //  camera position
      var _r = 6.0
      var _r0 = Math.random() * Math.PI * 2.0
      var _r1 = Math.random() * Math.PI * 2.0
      var _x = Math.cos(_r0) * Math.sin(_r1) * _r
      var _y = Math.cos(_r0) * Math.cos(_r1) * _r
      var _z = Math.sin(_r0) * _r
      var _pos = new THREE.Vector3(_x, _y, _z)

      Tweener.addTween(_camera, {
        position: _pos.add(_dat.wrapper.position),
        duration: _duration,
        transition: 'easeInOutExpo',
      })
    }

    clearTimeout(_this.randomViewerKey)
    //  delay
    _this.randomViewerKey = setTimeout(function () {
      //  nextTime
      _this.randomViewer()
    }, _duration * 1000 + ~~(Math.random() * 2000))
  }

  loop() {
    var _this = this
    this.loopKey0 = window.requestAnimationFrame(function () {
      _this.loop()
    })

    //  rot
    var _smfg = this.json.data
    for (var i in _smfg) {
      _smfg[i].wrapper.rotation.x += 0.0005
      _smfg[i].wrapper.rotation.y += 0.0005
    }

    //  camera ||
    // var _f = this.props.world.focus;
    // var _c = this.props.world.camera;
    // var _d = 6.0;
    // var _dir = new THREE.Vector3().subVectors( _c.position, _f );
    // var _dist = _dir.length();
    // _dist += ( _d - _dist ) * 0.05; //
    // _dir = _dir.normalize().multiplyScalar( _dist );
    // _dir.add( _f )
    // _c.position.set( _dir.x, _dir.y, _dir.z );

    //  dead or alive
    if (this.particles == undefined) {
      return
    }

    //  particles
    var len = this.particlesManager.length
    for (var i = 0; i < len; i++) {
      var _p = this.particlesManager[i]
      var _limit = _p.line.geometry.attributes.position.count

      //  update
      _p.position += _p.dir

      //  reset
      if (_p.position < 0 || _p.position >= _limit) {
        // reset
        var _idx = Math.floor(Math.random() * _this.lines.length)
        var _line = _this.lines[_idx]
        var _dir = Math.random() < 0.5 ? 1 : -1
        _dir *= Math.random() < 0.5 ? 1 : Math.random() < 0.5 ? 2 : 3
        var _position = _dir > 1 ? 0 : _line.geometry.attributes.position.count

        //  new
        _p.dir = _dir
        _p.line = _line
        _p.position = _position
      }

      //  new position set
      var _arr = _p.line.geometry.attributes.position.array
      var _pos = _p.position
      var _x = _arr[_pos * 3 + 0]
      var _y = _arr[_pos * 3 + 1]
      var _z = _arr[_pos * 3 + 2]

      this.particles.geometry.vertices[i].x = _x
      this.particles.geometry.vertices[i].y = _y
      this.particles.geometry.vertices[i].z = _z
    }

    this.particles.geometry.verticesNeedUpdate = true

    //  depthtest
    var len = this.json.data.length
    while (len) {
      len--
      var _dat = this.json.data[len]
      var _p0 = this.props.world.camera.position
      var _p1 = _dat.wrapper.position
      var _d = _p0.distanceTo(_p1)

      _dat.mesh.material.opacity = 0.25
      _dat.core.material.opacity = 0.2

      if (_d <= 6) {
        if (_d < 3) {
          _dat.label.material.opacity = 0.0
          _dat.mesh.material.opacity = 0.0
          _dat.core.material.opacity = 0.0
        } else {
          _dat.label.material.opacity = (_d - 3) / 3
          _dat.label.material.opacity =
            _dat.label.material.opacity < 0.0
              ? 0.0
              : _dat.label.material.opacity > 1.0
              ? 1.0
              : _dat.label.material.opacity
          _dat.mesh.material.opacity = _dat.label.material.opacity * 0.25
          _dat.core.material.opacity = _dat.label.material.opacity * 0.2
        }
      } else {
        _dat.label.material.opacity = 1.0 - (_d - 6) / 16
      }
    }
  }

  renderStart() {
    this.loop()
  }

  renderStop() {
    window.cancelAnimationFrame(this.loopKey0)
  }

  dispose() {
    window.cancelAnimationFrame(this.loopKey0)

    //  all
    Tweener.clearAllTweens()

    //  dekaykey
    clearTimeout(this.randomViewerKey)

    for (var i in this.timeoutKeyList) {
      clearTimeout(this.timeoutKeyList[i])
    }
    this.timeoutKeyList = null

    //  remove
    var len = this.scene.children.length
    while (len) {
      len--
      var _obj = this.scene.children.pop()
      this.scene.remove(_obj)

      if (_obj.type == 'Points' || _obj.type == 'Sprite' || _obj.type == 'Line' || _obj.type == 'Mesh') {
        if (_obj.material.map != undefined) {
          _obj.material.map.dispose()
        }

        _obj.geometry.dispose()
        _obj.material.dispose()
      }

      _obj = null
    }

    this.particles.material.map.dispose()
    this.particles.material.map = null

    this.lights = null
    this.lines = null
    this.particles = null
    this.particlesManager = null

    //  dlete master data
    this.json = null
  }
}
