export default class Loading {
  constructor() {
    this.body = document.body
    this.links = [...document.querySelectorAll('a')]
    this.links.forEach(this._transitionAnimation.bind(this))
    this.event = new CustomEvent('loaded')
    this.loaded = this._loaded.bind(this)
    window.addEventListener('pageshow', this.loaded)
  }

  _transitionAnimation(elm) {
    const { body } = this
    const target = elm.target
    if (target === '_blank') return
    elm.addEventListener('click', (event) => {
      event.preventDefault()
      const href = elm.href
      if (href === location.href) return true
      window.Velocity({
        e: body,
        p: 'fadeOut',
        o: {
          delay: 200,
          duration: 1000,
          visibility: 'hidden',
          display: 'block',
          complete: () => {
            location.href = href
          },
        },
      })
    })
  }

  _loaded() {
    const { body, event } = this
    window.Velocity({
      e: body,
      p: 'fadeIn',
      o: {
        delay: 200,
        duration: 1000,
        visibility: 'visible',
        complete: () => {
          window.dispatchEvent(event)
        },
      },
    })
  }
}
