export default class CatchAnime {
  constructor(elm) {
    this.text = [...elm.querySelectorAll('span')]
    this.random = []
    this.min = 1
    this.max = this.text.length
    this._randomInit()
    window.addEventListener('load', () => this._loaded())
  }

  _randomInit(max = this.max, min = this.min) {
    this.index = 0
    let initAry = []
    for (let i = 0; i < max; i++) {
      initAry.push(min + i)
    }

    while (initAry.length > 0) {
      this.random.push(initAry.splice(this._getRandomInt(0, initAry.length), 1).pop())
    }
  }

  _getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min
  }

  _loaded() {
    this.text.forEach(this._textAnimation.bind(this))
  }

  _textAnimation(elm, index) {
    window.Velocity({
      e: elm,
      p: {
        rotateY: ['0deg', '-180deg'],
        opacity: [1, 0],
      },
      o: {
        delay: 800 + this.random[index] * 30,
        duration: 300,
        display: 'inline-block',
        visibility: 'visible',
      },
    })
  }
}
