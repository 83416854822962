/*
	ArtworkGL.js
*/

import * as THREE from 'three'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'
import Tweener from './Tweener'
import axios from 'axios'

export default class mainView {
  constructor(props) {
    this.props = props

    this.scene
    this.main

    this.lights = []
    this.lines = []
    this.particles
    this.particlesManager

    //  loopkey
    this.loopKey0
    this.wrapper

    this.json
    this.loadingData
    this.vector
    this.randomViewerKey
    this.timeoutKeyList

    this.__init()
  }

  __init() {
    this.init()

    var _this = this
    this.loadJSON(function () {
      _this.layouts()
      _this.loop()
    })
  }

  init() {
    this.scene = new THREE.Scene()

    var _al = new THREE.AmbientLight(0xffffff, 0.6)
    this.scene.add(_al)

    var _dl = new THREE.PointLight(0xffffff, 1.0)
    _dl.position.set(10, 30, 20)
    _dl.castShadow = true // default false
    _dl.shadow.radius = 4
    this.props.world.add(_dl)

    this.lights.push(_al)
    this.lights.push(_dl)

    var _renderer = this.props.world.renderer

    _renderer.outputEncoding = THREE.GammaEncoding

    this.timeoutKeyList = []
  }

  loadJSON(_callback) {
    //  path
    let _this = this
    const url = '/assets/models/smfgdata.json'
    let _json = axios.get(url).then((res) => {
      _this.json = res.data

      // console.log(_this.json)
      _this.json.coreData = []
      _this.json.models.reverse()
      _this.loadList(_callback)
    })
  }

  loadList(_callback) {
    let _this = this
    if (this.json.models.length) {
      let _path = this.json.models.pop()
      let _url = '/assets/models/' + _path

      var loader = new FBXLoader()
      loader.load(
        _url,
        function (object) {
          object.scale.set(1, 1, 1)
          object.traverse(function (child) {
            child.scale.set(1, 1, 1)

            if (child.isMesh) {
              child.material.wireframe = true
            }
          })

          _this.json.coreData.push(object)

          // delay load.
          var _k = setTimeout(function () {
            _this.loadList(_callback)
          }, 16)
          _this.timeoutKeyList.push(_k)
        },
        function (_onProgress) {
          // console.log(_onProgress)
        },
        function (_onError) {
          console.log('ERROR: ', _onError)
        }
      )
    } else {
      _callback()
    }
  }

  layouts() {
    var _smfg = this.json.data
    var _idx = 0
    var _dat = _smfg[0]
    for (var i in this.json.data) {
      if (this.json.data[i].id == this.props.id) {
        // _idx = i;
        _idx = this.json.data[i].type
        _dat = this.json.data[i]
        break
      }
    }

    var _wrapper = new THREE.Object3D()
    _wrapper.rotation.x = Math.random() * Math.PI * 2.0
    _wrapper.rotation.y = Math.random() * Math.PI * 2.0
    _wrapper.rotation.z = Math.random() * Math.PI * 2.0

    //  WIREFRAME
    var _master = this.json.coreData[_idx].children[0]
    var _geometry = _master.geometry.clone()
    var _material = _master.material.clone()
    var _material = new THREE.MeshBasicMaterial()
    _material.wireframe = true
    _material.color = new THREE.Color(_dat.color)
    _material.transparent = true
    _material.opacity = 0.25
    _material.blending = THREE.AdditiveBlending
    var _mesh = new THREE.Mesh(_geometry, _material)
    _wrapper.add(_mesh)

    //  CORE
    var _geometry = new THREE.IcosahedronGeometry(0.4, 1)
    var _material = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0.2,
    })
    var _core = new THREE.Mesh(_geometry, _material)
    _wrapper.add(_core)

    if (_dat.type == 2) {
      _core.scale.set(0.9, 0.9, 0.9)
    }

    this.scene.add(_wrapper)

    this.props.world.camera.position.z = 3
    this.wrapper = _wrapper
  }

  randomViewer() {
    if (this.json.data != undefined) {
      var _duration = 8.0

      var _this = this

      var _idx = Math.floor(Math.random() * this.json.data.length)
      var _dat = this.json.data[_idx]
      var _world = this.props.world
      var _camera = this.props.world.camera

      //  camera focus
      Tweener.addTween(_world, {
        focus: _dat.wrapper.position,
        duration: _duration - 1.0,
        transition: 'easeInOutExpo',
      })

      //  camera position
      var _r = 6.0
      var _r0 = Math.random() * Math.PI * 2.0
      var _r1 = Math.random() * Math.PI * 2.0
      var _x = Math.cos(_r0) * Math.sin(_r1) * _r
      var _y = Math.cos(_r0) * Math.cos(_r1) * _r
      var _z = Math.sin(_r0) * _r
      var _pos = new THREE.Vector3(_x, _y, _z)

      Tweener.addTween(_camera, {
        position: _pos.add(_dat.wrapper.position),
        duration: _duration,
        transition: 'easeInOutExpo',
      })
    }

    clearTimeout(_this.randomViewerKey)
    //  delay
    _this.randomViewerKey = setTimeout(function () {
      //  nextTime
      _this.randomViewer()
    }, _duration * 1000 + ~~(Math.random() * 2000))
  }

  loop() {
    var _this = this
    this.loopKey0 = window.requestAnimationFrame(function () {
      _this.loop()
    })

    this.wrapper.rotation.x += 0.005
    this.wrapper.rotation.y += 0.005
  }

  renderStart() {
    this.loop()
  }

  renderStop() {
    window.cancelAnimationFrame(this.loopKey0)
  }

  dispose() {
    window.cancelAnimationFrame(this.loopKey0)

    //  all
    Tweener.clearAllTweens()

    //  dekaykey
    clearTimeout(this.randomViewerKey)

    for (var i in this.timeoutKeyList) {
      clearTimeout(this.timeoutKeyList[i])
    }
    this.timeoutKeyList = null

    //  remove
    var len = this.scene.children.length
    while (len) {
      len--
      var _obj = this.scene.children.pop()

      if (_obj.type == 'Object3D') {
        var len2 = _obj.children.length
        while (len2) {
          len2--
          var _obj2 = _obj.children.pop()
          if (_obj2.material && _obj2.material.map != undefined) {
            _obj2.material.map.dispose()
            _obj2.material.map = null
          }

          _obj.remove(_obj2)
        }
      }

      if (_obj.material && _obj.material.map != undefined) {
        _obj.material.map.dispose()
        _obj.material.map = null
      }

      this.scene.remove(_obj)
      _obj = null
    }

    //  dlete master data
    this.json = null
  }
}
