import detect from 'core/detect'
import ArtworkGL from './ArtworkGL'

export default class ArtworkThumb {
  constructor(elm) {
    this.canvas = elm.querySelector('canvas')
    if (detect.isAndroid() && !elm.classList.contains('background-company')) {
      elm.classList.add('is-android')
      this.canvas.style.display = 'none'
      return
    }

    this.id = elm.dataset.id
    this.width = elm.clientWidth
    this.height = elm.clientHeight
    if (this.width === 0 || this.height === 0) return
    this.artworkGL = new ArtworkGL({
      $canvas: this.canvas,
      $bgColor: 0x000000,
      $id: this.id,
      $width: this.width,
      $height: this.height,
    })
  }

  destroyed() {
    this.artworkGL.dispose()
  }
}
