export default class Slider {
  get Opt() {
    return {
      centeredSlides: true,
      slidesPerView: 'auto',
      spaceBetween: 40,
      pagination: {
        el: `[data-elm='number']`,
        type: 'fraction',
        renderFraction: (current, total) => {
          return `<span class='number-current ${current}'></span><span class='slash'>/</span><span class='number-all ${total}'></span>`
        },
      },
      navigation: {
        nextEl: '.button-next',
        prevEl: '.button-prev',
      },
    }
  }

  constructor(elm) {
    this.elm = elm
    this.breakPoint = 767
    this.controller = null
    this.bool = false
    window.addEventListener('load', this.load.bind(this))
    window.addEventListener('resize', this.resize.bind(this))
  }

  load() {
    const { breakPoint } = this
    if (breakPoint < window.innerWidth) {
      this.bool = false
    } else {
      this.create()
      this.bool = true
    }
  }

  resize() {
    const { breakPoint, bool } = this
    if (breakPoint < window.innerWidth && bool) {
      this.controller.destroy(false, true)
      this.bool = false
    } else if (breakPoint >= window.innerWidth && !bool) {
      this.create()
      this.bool = true
    }
  }

  create() {
    const { Opt } = this
    this.controller = new window.Swiper(this.elm, Opt)
  }
}
