export default class AddClass {
  constructor(elm, type) {
    if (type !== 'addClass') {
      return true
    }
    this.elm = elm
  }

  run() {
    const { elm } = this
    elm.classList.add('is-show')
  }
}
