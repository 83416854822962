const DEVELOP = 'development'
const PRODUCTION = 'production'

const env = {
  ENV: /^localhost|testup.website|repo.testup.me|^[0-9]{0,3}\.[0-9]{0,3}\.[0-9]{0,3}\.[0-9]{0,3}/g.test(
    document.domain
  )
    ? DEVELOP
    : PRODUCTION,
  isDev () {
    return this.ENV === DEVELOP
  },
  isProd () {
    return this.ENV === PRODUCTION
  }
}

export default env
